@import url('https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;700&display=swap');

/* Common styles */
.App {
  text-align: center;
  background-size: cover;
  background-position: 40% 85%;
  font-family: 'Manrope', sans-serif;
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  transition: background-image 0.5s ease-in-out;
}

.App-header {  
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px;
  box-sizing: border-box;
  height: 150px;
  backdrop-filter: blur(3px);
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
}

.progress {
  position: absolute;
  font-size: 40px;
  font-weight: bold;  
}

.top-left {
  top: 20px;
  left: 40px;  
}

.top-right {
  top: 20px;
  right: 120px;
}

.top-center {
  top: 60px;
  left: calc(50% - 250px);
  font-size: 24px;
  font-weight: lighter;
  letter-spacing: 0.05rem; 
  word-spacing: 1rem;
}

.top-center a {
  text-decoration: none; /* Remove underline */
  margin: 0 10px; /* Add spacing between links */
  font-size: 24px;
  font-weight: lighter;
}

.top-center a.active {
  color: rgba(0, 173, 147, 1); /* Highlight color for the active link */
}


.top-center a:hover {
  color: rgb(191, 189, 189); /* Optional: add a hover color */
}

a:visited {
    color: #0095da; /* Color for visited links */
}

.progress span {
  font-size: 20px;
  font-weight: lighter;
  display: block; 
  margin-top: 0px;
}

.dropdown {
  position: absolute;
  right: 20px;
  top: 30px;
  display: inline-block;  
  z-index: 10;
}

.dropbtn {
  background-color: #ffffff84;
  border-radius: 4px;
  padding: 15px;
  font-size: 15px;
  border: 2px;
  cursor: pointer;
  justify-content: left;
}

.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  min-width: 220px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 11;
  text-align: left;
}

.dropdown-content a {
  color: whitesmoke;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  background-color: #0095da00;
}

.dropdown-content a:hover {
  background-color: #f1f1f100;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.App-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
  padding: 20px;
  font-size: calc(10px + 1.5vmin);
  /*
  overflow: hidden;
  */
}

.glass {
  background: rgba(255, 255, 255, 0);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
  backdrop-filter: blur(3.4px);
  -webkit-backdrop-filter: blur(3.4px);  
  color: white;

  /*
  padding: 50px 100px 50px 100px;
  background: rgba(153, 153, 153, 0.1);
  border-radius: 10px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(3.4px);
  -webkit-backdrop-filter: blur(3.4px);  
  border: 2px solid rgba(221, 221, 221, 0.21);
  height: 250px;
  width: 900px;  
  */
}

.glass-transparent {
  padding: 20px;
  margin-bottom: 20px;
  text-align: left;
  overflow-y: auto;
  max-width: 100%;
  /*background: rgba(153, 153, 153, 0.1);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(221, 221, 221, 0.21);*/
}


.center-align {
  text-align: center;
}

.glass.img {
  align-self: center;
}

.glass.vid {
  align-self: center;
}

.cursor {
  display: inline-block;
  width: 15px;
  animation: flicker 0.75s infinite;
  margin-bottom: 0px;
}

@keyframes flicker {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}


@keyframes my-input {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.slick-prompt{
  height: 50px !important;
  color: white;
  background-color: rgba(255, 255, 255, 0.0);
  transition: opacity, transform 5s ease-in;
  opacity: 1;
  transform: translateY(0);
  display: block;
  margin: 0 auto;
  padding-bottom: 24px;
  resize: none;
  border: none;
  outline: none;
  font-family: inherit;
  overflow: hidden;
  font-size: 24px;
  width: calc(100% - 40px);
  padding-right: 96px;
  line-height: 120%;
  font-weight: 500;
  padding-top: 0px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: transparent;
  animation: my-input 30s ease-in-out;
}

/* For the input's placeholder */
.slick-prompt::placeholder {
  color: #dfdfdf97; 
}

textarea.slick-prompt {
  height: 100px; 
}

.prompt{
  padding: 25px 25px 5px 25px;
  height: 50px;
  transition: opacity, transform 500ms ease-in;
  font-size: calc(8px + 1.5vmin);
  opacity: 1;
  transform: translateY(0);
  color:  rgba(0, 173, 147, 1);
}

a:link {
  color: #0095da;
  text-decoration: none;
}

.custom-button-expansion {
  background-color: #018e95fe;
  border-radius: 5px;
  padding: 9px;
  margin: 0px 10px 0px 20px;
  min-width: 100px;
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  color: white !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-button-retention {
  background-color: #cc0085f5;
  border-radius: 5px;
  padding: 9px;
  min-width: 100px;
  margin: 0px 10px 0px 20px;
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  color: white !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-button-acquisition {
  background-color: #187fced4;
  min-width: 100px;
  border-radius: 5px;
  padding: 9px;
  margin: 0px 10px 0px 20px;
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  color: white !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.custom-button-prob {
  background-color: #00a927ad;
  border-radius: 5px;
  min-width: 100px;
  padding: 9px;
  margin: 0px 20px 0px 0px;
  text-align: center;
  display: inline-block;
  font-size: 1rem;
  color: white !important;
}

@media (max-width: 414px) { /* Specific for iPhone XR */
  .App-header {
    flex-direction: column;
    height: auto;
  }

  .progress {
    margin-bottom: 10px;
    font-size: 20px;
  }

  .progress span {
    font-size: 10px;
    font-weight: lighter;
  }  

  .App-content {
    padding: 10px;
  }

  .markdown-content {
    font-size: 0.8rem;
  }

  .markdown-content h2 {
    font-size: 1.1rem;
  }

  .slick-prompt {
    font-size: 17px; /* making it larger than 16px so that the app doesn't zoom in automatically. */
  }
}


/* Light theme */
.App.light .App-header {
  color: black;
}

.App.light .top-center a {
  color: black; /* Default link color */
}

.App.light .top-center a:hover {
  color: gray; /* Optional: add a hover color */
}

.App.light .top-center a.active {
  color: rgba(0, 173, 147, 0.901); /* Highlight color for the active link */
}

.App.light .progress span {
  color: #333;
}

.App.light .dropbtn {
  color: rgb(2, 2, 2);
}

.App.light .dropdown-content {
  background-color: #f9f9f9;
}

.App.light .dropdown-content a {
  color: black;
}

.App.light .dropdown-content a:hover {
  background-color:#f1f1f1;
}

.App.light .glass {
  color: black;
}

.App.light .slick-prompt {
  color: black;
}

.App.light .prompt {
  opacity: 0.8;
}

.App.light .cursor {
  fill: black;
}





/* Dark Theme */
/* Add a slight layer on top of background to make foreground easier to read */
.App.dark::before {
  content: '';
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.App.dark .App-header {
  color: white;
}

.App.dark .top-center a {
  color: white; /* Default link color */
}

.App.dark .top-center a:hover {
  color: rgb(191, 189, 189); /* Optional: add a hover color */
}

.App.dark .top-center a.active {
  color: rgba(0, 173, 147, 0.901); /* Highlight color for the active link */
}

.App.dark .progress span {
  color: #fff;
}

.App.dark .dropbtn {
  color: white;
}

.App.dark .dropdown-content {
  background-color: #ffffff;
}

.App.dark .dropdown-content a {
  color: black;
  background-color: #0095da00;
}

.App.dark .dropdown-content a:hover {
  background-color:rgba(0, 173, 147, 0.901);
}

.App.dark .glass {
  color: white;
}

.App.dark .slick-prompt {
  color: white;
}

/* For the input's placeholder */
.App.dark .slick-prompt::placeholder {
  color: #dfdfdf97; 
}

.App.dark .prompt {
  color: rgba(0, 173, 147, 1);
  opacity: 1;
}

.App.dark .cursor {
  fill: white;
}




/* Added for laying out side-by-side chat & frame */
.main-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.chat-container {
  /*flex: 1;*/
  display: flex;
  flex-direction: column;
  /*width: 30%; */ /* Set a fixed width for the chat container */
  max-width: 1400px;  /* Optionally set a max width */
  width: 750px; /* Full width initially */
  transition: all 1s ease-in-out; /* Smooth transition */
  justify-content: center; /* Center content vertically */
  align-items: center; /* Center content horizontally */
  position: relative;
  
  /*background: rgba(153, 153, 153, 0.1);*/  
  padding: 50px;
  margin: 20px 4px 20px 20px;
  border-radius: 15px;  
  z-index: 1;
  backdrop-filter: blur(3.4px);
  -webkit-backdrop-filter: blur(3.4px);  
  /*  Moved this to the .move-left version because 
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(221, 221, 221, 0.21);
  */
}

.chat-container.move-left {
  width: 30%; /* Adjust width when iframe is visible */
  /*align-items: flex-start;*/ /* Align items to the left */
  box-shadow: 2px 4px 6px 0px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(221, 221, 221, 0.21);
}

.iframe-container {
  flex: 1;
  display: flex;
  justify-content: center;  
  align-items: center;
  padding: 20px 20px 20px 0px;
  transition: transform 1s ease-in-out, opacity 1s ease-in-out;
  opacity: 1; /* Initially hidden when rendered*/
  transform: translateX(0); /* Initially in place when rendered */
  position: relative; 
  z-index:2;
}

.close-button {
  position: absolute;
  top: 130px;
  right: 40px;
  background: transparent;
  border: none;
  font-size: 30px;
  cursor: pointer;
  z-index: 10;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.iframe-container.slide-out {
  transform: translateX(-100%);
  animation: fadeOut 1s 0.5s forwards;  /* Fade out */
}

.iframe-container.slide-in {
  transform: translateX(0);
  animation: fadeIn 1.5s forwards; /* Delayed fade-in */
}

.iframe-container iframe {
  width: 100%;
  max-width: 100%;
  max-height: 70%;
  aspect-ratio: 16/9; /* Maintain aspect ratio */

  /*border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);*/
  background: rgba(255, 255, 255, 0);
  border-radius: 0px 10px 10px 0px;
  padding: 0px;
  backdrop-filter: blur(3.4px);
  -webkit-backdrop-filter: blur(3.4px);  
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(221, 221, 221, 0.21);
}

@media (max-width: 1024px) {
  .main-container {
    flex-direction: column;
  }

  .iframe-container {
    padding: 10px;
  }

  .iframe-container iframe {
    max-width: 100%;
  }

  .chat-container.move-left {
    width: 100%;
    align-items: center; /* Center items for mobile */
  }
}

/* This is for code blocks for the Zoho update */
code {
  padding: 2px 2px;         /* Padding around inline code */
  font-family: 'Courier New', Courier, monospace; /* Monospaced font */
  font-size: 15px;          /* Font size */
  color: #d7d7d7;           /* Text color */
}